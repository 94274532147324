import React, { Component } from 'react';
import {Container, Table, Menu, Button } from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars

import _ from 'lodash'; // eslint-disable-line no-unused-vars

import {BASE_CLIENT_URL, BASE_API_URL} from './Config';
import TopHeader from './TopHeader';

function table_content(images, image_selector) {
    if (images[image_selector]['is_core'] === 2) {
        return (
            <div>
                SRH:
                <br></br>
                {images[image_selector]['NIO_barcode']}
            </div>
        );
    } else if (images[image_selector]['is_core'] === 3) {
        return (
            <div>
                H&amp;E: 
                <br></br>
                {images[image_selector]['NIO_barcode']}
            </div>
        );
    } else if (images[image_selector]['is_core'] === 1) {
        return (
            <div>
                CORE:
                <br></br>
                {images[image_selector]['NIO_barcode']}
            </div>
        );
    } else if (images[image_selector]['is_core'] === 0) {
        return (
            <div>
                Margin:
                <br></br>
                {images[image_selector]['NIO_barcode']}
            </div>
        );
    } else {
        return (
            <div>
                Image:
                <br></br>
                {images[image_selector]['NIO_barcode']}
            </div>
        );
    }
}

function read_element(recent_submit_time_string) {
    if ((recent_submit_time_string != null) && (recent_submit_time_string.length > 3)) {
        return(
            <div>
                <br>
                </br>
                Read on: {recent_submit_time_string}
            </div>
        );
    } else {
        return(
            <div>
            </div>
        );
    }
}

function TableRow(props) {
    const core_size = 'big';
    const margin_size = 'small';

    if (props.num_images === 0) {
        return (<></>);
    } else if (props.num_images === 1) {
        return (
            <Table.Row style={{backgroundColor:(props.color_flag) ? 'rgba(100,100,100,0.075)': 'rgba(255,255,255)'}} key={props.images[props.image_counter]['db_id']}>
            {(props.display_header) ? <Table.Cell rowSpan={props.row_height}>{"Case " + props.images[props.image_counter]['randomized_case_number']}</Table.Cell> : <></>}
            {(props.display_header) ? <Table.Cell style={{whiteSpace: "pre-line"}} rowSpan={props.row_height}>{props.images[props.image_counter]['patient_history']}</Table.Cell> : <></>}
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter]['NIO_barcode'] + "_" + props.images[props.image_counter]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter)}
                </Link>
                {read_element(props.images[props.image_counter]['recent_submit_time_string'])}
            </Table.Cell>
            </Table.Row>
        );
    } else if (props.num_images === 2) {
        return (
            <Table.Row style={{backgroundColor:(props.color_flag) ? 'rgba(100,100,100,0.075)': 'rgba(255,255,255)'}} key={props.images[props.image_counter]['db_id']}>
            {(props.display_header) ? <Table.Cell rowSpan={props.row_height}>{"Case " + props.images[props.image_counter]['randomized_case_number']}</Table.Cell> : <></>}
            {(props.display_header) ? <Table.Cell rowSpan={props.row_height}>{props.images[props.image_counter]['patient_history']}</Table.Cell> : <></>}
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter]['NIO_barcode'] + "_" + props.images[props.image_counter]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter)}
                </Link>
                {read_element(props.images[props.image_counter]['recent_submit_time_string'])}
            </Table.Cell>
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter + 1]['NIO_barcode'] + "_" + props.images[props.image_counter + 1]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter + 1)}
                </Link>
                {read_element(props.images[props.image_counter + 1]['recent_submit_time_string'])}
            </Table.Cell>
            </Table.Row>
        );
    } else {
        return (
            <Table.Row style={{backgroundColor:(props.color_flag) ? 'rgba(100,100,100,0.075)': 'rgba(255,255,255)'}} key={props.images[props.image_counter]['db_id']}>
            {(props.display_header) ? <Table.Cell rowSpan={props.row_height}>{"Case " + props.images[props.image_counter]['randomized_case_number']}</Table.Cell> : <></>}
            {(props.display_header) ? <Table.Cell rowSpan={props.row_height}>{props.images[props.image_counter]['patient_history']}</Table.Cell> : <></>}
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter]['NIO_barcode'] + "_" + props.images[props.image_counter]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter)}
                </Link>
                {read_element(props.images[props.image_counter]['recent_submit_time_string'])}
            </Table.Cell>
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter + 1]['NIO_barcode'] + "_" + props.images[props.image_counter + 1]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter + 1)}
                </Link>
                {read_element(props.images[props.image_counter + 1]['recent_submit_time_string'])}
            </Table.Cell>
            <Table.Cell>
                <Link to={"/Viewer/" + props.institution + "_" + props.images[props.image_counter + 2]['NIO_barcode'] + "_" + props.images[props.image_counter + 2]['scan_number'] + ".dzi"}>
                    {table_content(props.images, props.image_counter + 2)}
                </Link>
                {read_element(props.images[props.image_counter + 2]['recent_submit_time_string'])}
            </Table.Cell>
            </Table.Row>
        );
    }
}

export default class Homepage extends Component {
    constructor(props) {
        super(props);
    
        this.state = {tableData: {}, signedInUser:"", institution:"a"};

        // This binding is necessary to make `this` work in the callback
        this.logoutClicked = this.logoutClicked.bind(this);
        this.returnRows = this.returnRows.bind(this);
    }

    returnRows(tableData) {
        var output = [];
        var colored_flag = false;
        for (var btrc in tableData) {
            const images = tableData[btrc];

            output.push(<TableRow color_flag={colored_flag} row_height={1 + Math.ceil((images.length - 1) / 3)} num_images={1} display_header={true} image_counter={0} images={images} institution={this.state.institution}></TableRow>);

            if (images.length <= 4) {
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 1} display_header={false} image_counter={1} images={images} institution={this.state.institution}></TableRow>);
            } else if (images.length <= 7) {
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 1} display_header={false} image_counter={1} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 4} display_header={false} image_counter={4} images={images} institution={this.state.institution}></TableRow>);
            } else if (images.length <= 10) {
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 1} display_header={false} image_counter={1} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 4} display_header={false} image_counter={4} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 7} display_header={false} image_counter={7} images={images} institution={this.state.institution}></TableRow>);
            } else if (images.length <= 13) {
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 1} display_header={false} image_counter={1} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 4} display_header={false} image_counter={4} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 7} display_header={false} image_counter={7} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 10} display_header={false} image_counter={10} images={images} institution={this.state.institution}></TableRow>);
            } else if (images.length <= 16) {
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 1} display_header={false} image_counter={1} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 4} display_header={false} image_counter={4} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 7} display_header={false} image_counter={7} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 10} display_header={false} image_counter={10} images={images} institution={this.state.institution}></TableRow>);
                output.push(<TableRow color_flag={colored_flag} num_images={images.length - 13} display_header={false} image_counter={13} images={images} institution={this.state.institution}></TableRow>);
            }

            colored_flag = !colored_flag;
        }

        return output;
    }
    componentDidMount() {
        fetch(BASE_API_URL + "/get_all_cases",
        {
            method: "POST",
            headers: {
                "authorization": localStorage.getItem('authorization')
            }
        }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                localStorage.removeItem('authorization');
                window.location.href = BASE_CLIENT_URL;
            } else {
                const rows = data['data'];
                this.setState({
                    tableData : rows,
                    institution: data['institution']
                });
            }
        }.bind(this)).catch(function(err) {
            console.log(err);
            this.setState({tableData: []});
        }.bind(this));

        fetch(BASE_API_URL + "/get_signed_in_desc",
            {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem('authorization')
                }
            }
            ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                localStorage.removeItem('authorization');
                window.location.href = BASE_CLIENT_URL;
            } else {
                this.setState({signedInUser:data['user_description']});
            }
        }.bind(this)).catch(function(err) {
            console.log(err);
            localStorage.removeItem('authorization');
            window.location.href = BASE_CLIENT_URL;
        }.bind(this));
    }

    logoutClicked() {
        alert('logout clicked');
    }

    render() {
      return (
        <div style={{fontFamily:'Helvetica'}}>
            <TopHeader></TopHeader>
        
            <div style={{backgroundColor:'#eee', marginTop:'2em', paddingBottom:'4em'}}>
                <p></p>
                <Container text style={{paddingTop:'75px'}}>
                    <div className="ui huge header" style={{fontSize:'4em'}}>SRH Image Viewer</div>
                    <p style={{fontSize:'1.25em'}}>Please scroll down to see a list of cases available for viewing and interpretation </p>
                </Container>
            </div>
        
            <Container>
            <br></br>
            <h3>You are signed in as: Dr. {this.state.signedInUser}</h3>
            <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Case Number</Table.HeaderCell>
                    <Table.HeaderCell>Clinical History</Table.HeaderCell>
                    <Table.HeaderCell colSpan='3'>SRH Images</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {this.returnRows(this.state.tableData)}
            </Table.Body>
            </Table>
            </Container>
        </div>
      );
    }
}
