import React, { Component } from 'react';
import {Container, Menu, Image} from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars

import {BASE_CLIENT_URL, BASE_API_URL} from './Config';
import Homepage from './Homepage';

export default class TopHeader extends Component {
    constructor(props) {
        super(props);
    
        // This binding is necessary to make `this` work in the callback
        this.logoutClicked = this.logoutClicked.bind(this);
    }

    logoutClicked() {
        localStorage.removeItem('authorization');
        window.location.href = BASE_CLIENT_URL;
    }

    render() {
        return (
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item as='a' header>
                        Invenio Image Portal
                    </Menu.Item>

                    <Menu.Item as={Link} to='/Homepage'>Home</Menu.Item>

                    <Menu.Item as='a'>Data</Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item as='a'
                            name='logout'
                            onClick={this.logoutClicked}
                        />
                        </Menu.Menu>
                </Container>
            </Menu>
        );
    }
}