import React, { Component } from 'react';
import {Container, Table, Menu, Input, Button, Divider, Dropdown, Segment, Grid} from 'semantic-ui-react'; // eslint-disable-line no-unused-vars
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars
import OpenSeadragon from 'openseadragon';
import {scalebar_enable} from './openseadragon-scalebar';

import {BASE_CLIENT_URL, BASE_API_URL} from './Config';
import TopHeader from './TopHeader';

const queryString = require('query-string');


var seadragon_div_style = {
    width: '100%',
    height: '100vh',
    'backgroundColor': '#BBB'
};

export default class Thinviewer extends Component {
    constructor(props) {
        super(props);

        scalebar_enable(OpenSeadragon);

        var barcode = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1).substring(5).replace(".dzi","");
        barcode = barcode.substring(0, barcode.lastIndexOf("_"));

        var x1 = queryString.parse(this.props.location.search)['x1'];
        var y1 = queryString.parse(this.props.location.search)['y1'];
        var x2 = queryString.parse(this.props.location.search)['x2'];
        var y2 = queryString.parse(this.props.location.search)['y2'];

        if (x1 != undefined) {
            x1 = parseFloat(x1);
        }

        if (x2 != undefined) {
            x2 = parseFloat(x2);
        }

        if (y1 != undefined) {
            y1 = parseFloat(y1);
        }

        if (y2 != undefined) {
            y2 = parseFloat(y2);
        }

        var date = new Date();
        const date_string = date.toISOString();

        this.state = {
            globalViewer: undefined,
            caseNumber:"",
            barcode:barcode,
            animation_record: [],
            institution: "",
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2
        };

        // This binding is necessary to make `this` work in the callback
        this.zoom_handler = this.zoom_handler.bind(this);
    }

    
    zoom_handler() {
        if ((this.state.globalViewer.world == undefined) ||
            (this.state.globalViewer.viewport == undefined) ||
            (this.state.globalViewer.world.getItemAt(0) == undefined) ||
            (this.state.globalViewer.world.getItemAt(0).getFullyLoaded() == false)) {
            // not loaded yet
            setTimeout(this.zoom_handler, 100);
        } else {
            var bounds = this.state.globalViewer.viewport.getBounds(true);
            var currentRect = this.state.globalViewer.viewport.viewportToImageRectangle(bounds);

            // correct for different sizes of iframe/window here
            var desired_center_point_x = (0.5 * (this.state.x2 + this.state.x1));
            var desired_center_point_y = (0.5 * (this.state.y2 + this.state.y1));
            var desired_width = this.state.x2 - this.state.x1;
            var desired_height = this.state.y2 - this.state.y1;

            var window_size = this.state.globalViewer.viewport.getContainerSize;

            var top_left_x_pixel = desired_center_point_x - (desired_width / 2);
            var top_left_y_pixel = desired_center_point_y - (desired_height / 2);
            var bot_right_x_pixel = desired_center_point_x + (desired_width / 2);
            var bot_right_y_pixel = desired_center_point_y + (desired_width / 2);

            var width = (bot_right_x_pixel - top_left_x_pixel)
            var height = (bot_right_y_pixel - top_left_y_pixel);

            var rect = new OpenSeadragon.Rect(parseFloat(top_left_x_pixel) / this.state.globalViewer.world.getItemAt(0).getContentSize()['x'] , parseFloat(top_left_y_pixel) / this.state.globalViewer.world.getItemAt(0).getContentSize()['y'], width / this.state.globalViewer.world.getItemAt(0).getContentSize()['x'], height / this.state.globalViewer.world.getItemAt(0).getContentSize()['y']);
            this.state.globalViewer.viewport.fitBounds(rect, true);
        }
    }

    componentDidMount() {
        var pixelsPerMeter = 2000000;

        var viewer = OpenSeadragon({
            id: "openseadragon1",
            prefixUrl: "/images/",
            showNavigator:  true,
            maxZoomLevel: 25,
            loadTilesWithAjax:true,
            ajaxHeaders : {},
            tileSources: [
                            BASE_API_URL + '/thinslides/' + this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1)
                        ],
            sequenceMode: true,
            showReferenceStrip: true,
            gestureSettingsMouse: {clickToZoom: false},
            gestureSettingsTouch: {clickToZoom: false}
        });

        viewer.scalebar({
            type: OpenSeadragon.ScalebarType.MICROSCOPY,
            pixelsPerMeter: pixelsPerMeter,
            minWidth: "75px",
            location: OpenSeadragon.ScalebarLocation.TOP_LEFT,
            xOffset: 10,
            yOffset: 50,
            stayInsideImage: true,
            color: "rgb(100, 100, 100)",
            fontColor: "rgb(40, 40, 40)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            fontSize: "small",
            barThickness: 2
        });

        this.setState({globalViewer: viewer});
        if ((this.state.x1 != undefined) && (this.state.y1 != undefined) && (this.state.x2 != undefined) && (this.state.y2 != undefined)) {
            setTimeout(this.zoom_handler, 100);
        }

    }

    render() {
        return (
        <div style={{fontFamily:'Helvetica'}}>
                <center>
                    <div id="openseadragon1" style={seadragon_div_style}></div>
                </center>
        </div>
        );
    }
}
