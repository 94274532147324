import React, { Component } from 'react';
import {Container, Table, Menu, Input, Button, Divider, Dropdown, Segment, Grid} from 'semantic-ui-react'; // eslint-disable-line no-unused-vars
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars
import { MultiSelect } from "react-multi-select-component";
import OpenSeadragon from 'openseadragon';
import {scalebar_enable} from './openseadragon-scalebar';
import Modal from 'react-modal';
import {guide_enable} from './openseadragon-guides';
import './openseadragon-guides.css';
import {BASE_CLIENT_URL, BASE_API_URL} from './Config';
import TopHeader from './TopHeader';

const seadragon_div_style = {
    width: 1024 + 'px',
    height: 768 + 'px',
    'backgroundColor': '#BBB'
};

const line_break_style = {
    'white-space': 'pre-wrap'
};

const modal_style = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const diagnosis_options = [
    { key: 'Ependymoma', value: 'Ependymoma', text: 'Ependymoma' },
    { key: 'Malignant Glioma', value: 'Malignant Glioma',  text: 'Malignant Glioma' },
    { key: 'Grey Matter', value: 'Grey Matter',  text: 'Grey Matter' },
    { key: 'Diffuse LGG', value: 'Diffuse LGG', text: 'Diffuse LGG' },
    { key: 'Lymphoma', value: 'Lymphoma', text: 'Lymphoma' },
    { key: 'Medulloblastoma', value: 'Medulloblastoma',  text: 'Medulloblastoma' },
    { key: 'Meningioma', value: 'Meningioma',  text: 'Meningioma' },
    { key: 'Metastasis', value: 'Metastasis',  text: 'Metastasis' },
    { key: 'Nondiagnostic', value: 'Nondiagnostic',  text: 'Nondiagnostic' },
    { key: 'Pilocytic Astrocytoma', value: 'Pilocytic Astrocytoma', text: 'Pilocytic Astrocytoma' },
    { key: 'Pituitary Adenoma', value: 'Pituitary Adenoma', text: 'Pituitary Adenoma' },
    { key: 'Gliosis', value: 'Gliosis', text: 'Gliosis' },
    { key: 'Schwannoma', value: 'Schwannoma',  text: 'Schwannoma' },
    { key: 'White Matter', value: 'White Matter',  text: 'White Matter' },
    { key: 'Other (Free Text)', value: 'Other (Free Text)', text: 'Other (Free Text)' }
];

const cellularity_options = [
    {key:'0', value:'0', text:'0 -- No definitive tumor cells present'},
    {key:'1', value:'1', text:'1 -- Low abundance of infiltrating tumor cells'},
    {key:'2', value:'2', text:'2 -- Moderate abundance of infiltrating tumor cells'},
    {key:'3', value:'3', text:'3 -- Highly cellular with abundant tumor cells'}
];

const nio_image_quality_options = [
    {key:'equivalent_to_frozen_smear_preparation', value:'equivalent_to_frozen_smear_preparation', text: 'Equivalent to frozen/smear preparation'},
    {key:'better_than_frozen_smear_preparation', value:'better_than_frozen_smear_preparation', text: 'Better than frozen/smear preparation'},
    {key:'specimen_sampling_inadequate', value:'specimen_sampling_inadequate', text: 'Specimen sampling inadequate'},
    {key:'image_quality_inadequate', value:'image_quality_inadequate', text: 'Image quality inadequate'}
];

const yes_no_options = [
    {key:'yes', value:'yes', text:'Yes'},
    {key:'no', value:'no', text:'No'}
];

const hoag_yes_no_options = [
    {key:'frozen_needed_yes', value:'frozen_needed_yes', text:'Yes'},
    {key:'frozen_needed_no', value:'frozen_needed_no', text:'No'}
];

const new_cellularity_options = [
    {key:'0', value:'0', text:'0 -- No or low abundance of infiltrating tumor cells'},
    {key:'1', value:'1', text:'1 -- Moderate abundance of infiltrating tumor cells'},
    {key:'2', value:'2', text:'2 -- Highly cellular with abundant tumor cells'},
    {key:'NA', value:'NA', text:'Image quality was insufficient'}
];

const hoag_interpretation_categories = [
    {key:'neoplastic_lesion_excluding_lymphoma', value:'neoplastic_lesion_excluding_lymphoma', text:'Neoplastic Lesion (Excluding Lymphoma)'},
    {key:'lymphoma', value:'lymphoma', text:'Lymphoma'},
    {key:'non_lesional_tissue_gliosis_normal', value:'non_lesional_tissue_gliosis_normal', text:'Non-lesional tissue (gliosis, normal)'},
    {key:'non_diagnostic', value:'non_diagnostic', text:'Non Diagnostic'}
];

const hoag_final_interpretation_categories = [
    {key:'neoplastic_lesion_excluding_lymphoma', value:'neoplastic_lesion_excluding_lymphoma', text:'Neoplastic Lesion (Excluding Lymphoma)'},
    {key:'lymphoma', value:'lymphoma', text:'Lymphoma'},
    {key:'non_lesional_tissue_gliosis_normal', value:'non_lesional_tissue_gliosis_normal', text:'Non-neoplastic tissue (normal brain, reactive gliosis, abscess, inflammatory diseases, demyelination, benign cysts, infarction)'},
    {key:'non_diagnostic', value:'non_diagnostic', text:'Non-diagnostic tissue (completely necrotic tissue, blood clot)'}
];

const nioa_interpretation_categores = [
    {key:'high_grade_glioma', value:'high_grade_glioma', text:'High Grade Glioma',},
    {key:'low_grade_glioma', value:'low_grade_glioma', text:'Low Grade Glioma'},
    {key:'lymphoma', value:'lymphoma', text:'Lymphoma'},
    {key:'metastatic_cancer', value:'metastatic_cancer', text:'Metastatic Cancer'},
    {key:'pituitary_adenoma', value:'pituitary_adenoma', text:'Pituitary Adenoma'},
    {key:'meningioma', value:'meningioma', text:'Meningioma'}
];

const nyu_lesion = [
    {key:'normal', value:'normal', text:'Normal'},
    {key:'lesion_neoplasm', value:'lesion_neoplasm', text:'Lesion - Neoplasm'},
    {key:'lesion_non_neoplasm', value:'lesion_non_neoplasm', text:'Lesion - Non-neoplastic Disease'}
];

const nyu_intra_extra_axial = [
    {key:'na', value:'na', text:'N/A'},
    {key:'intra_axial', value:'intra_axial', text:'Intra-axial'},
    {key:'extra_axial', value:'extra_axial', text:'Extra-axial'}
];

const nyu_intra_axial_types = [
    {key:'na', value:'na', text:'N/A'},
    {key:'glial', value:'glial', text:'Glial'},
    {key:'non_glial', value:'non_glial', text:'Non-glial'},
    {key:'metastatic', value:'metastatic', text:'Metastatic'},
    {key:'high_grade', value:'high_grade', text:'High Grade'},
    {key:'low_grade', value:'low_grade', text:'Low Grade'},
];

const nyu_extra_axial_types = [
    {key:'na', value:'na', text:'N/A'},
    {key:'metastatic', value:'metastatic', text:'Metastatic'},
    {key:'meningioma', value:'meningioma', text:'Meningioma'},
    {key:'something_else', value:'something_else', text:'Something Else'},
];

const uncp_diagnostic_types = [
    {key:'lesional', value:'lesional', text:'Lesional'},
    {key:'non_diagnostic', value:'non_diagnostic', text:'Non-Diagnostic'}
];

const uncp_morphological_features_types = [
    {key:'cilia', value:'cilia', text:'Cilia'},
    {key:'cell_membrane', value:'cell_membrane', text:'Cell membrane'},
    {key:'nuclei', value:'nuclei', text:'Nuclei'},
    {key:'nucleoli', value:'nucleoli', text:'Nucleoli'},
    {key:'nuclear_membrane', value:'nuclear_membrane', text:'Nuclear Membrane'}
];

const uncp_he_concordant_types = [
    {key:'concordant_he', value:'concordant_he', text:'Concordant with H&E'},
    {key:'alternate_he', value:'alternate_he', text:'H&E suggests alternate diagnosis'},
    {key:'inadequate_he', value:'inadequate_he', text:'H&E inadequate'}
];


const confidence_options = [
    {key:'confidence_1', value:'confidence_1', text:'1'},
    {key:'confidence_2', value:'confidence_2', text:'2'},
    {key:'confidence_3', value:'confidence_3', text:'3'},
    {key:'confidence_4', value:'confidence_4', text:'4'},
    {key:'confidence_5', value:'confidence_5', text:'5'},
    {key:'confidence_6', value:'confidence_6', text:'6'},
    {key:'confidence_7', value:'confidence_7', text:'7'},
    {key:'confidence_8', value:'confidence_8', text:'8'},
    {key:'confidence_9', value:'confidence_9', text:'9'},
    {key:'confidence_10', value:'confidence_10', text:'10'}
];


const npa_lesional_options = [
    {key:'lesional_tissue', value:'lesional_tissue', text:'Lesional tissue'},
    {key:'non_lesional_tissue', value:'non_lesional_tissue', text:'Non-lesional tissue'},
    {key:'reactive_tissue', value:'reactive_tissue', text:'Reactive tissue'}
];

const npb_glial_diagnosis_options = [
    {key:'mildly_hypercellular_tissue', value:'mildly_hypercellular_tissue', text:'Mildly Hypercellular Tissue'},
    {key:'low_grade_glioma', value:'low_grade_glioma', text:'Low Grade Glioma'},
    {key:'high_grade_glioma', value:'high_grade_glioma', text:'High Grade Glioma'},
    {key:'glioblastoma', value:'glioblastoma', text:'Glioblastoma'},
    {key:'other_primary_brain_tumor', value:'other_primary_brain_tumor', text:'Other primary brain tumor (e.g., ependymoma, SEGA, or neuroglial)'}
];

const npb_non_glial_diagnosis_options = [
    {key:'metastasis', value:'metastasis', text:'Metastasis'},
    {key:'meningioma', value:'meningioma', text:'Meningioma'},
    {key:'pituitary_adenoma', value:'pituitary_adenoma', text:'Pituitary Adenoma'},
    {key:'nerve_sheath_tumor', value:'nerve_sheath_tumor', text:'nerve_sheath_tumor'},
    {key:'lymphoma', value:'lymphoma', text:'Lymphoma'},
    {key:'other_tumor', value:'other_tumor', text:'Other Tumor'}
];


const npa_axial_options = [
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'},
    {key:'intra_axial', value:'intra_axial', text:'Intra-axial'},
    {key:'extra_axial', value:'extra_axial', text:'Extra-axial'}
];

const npa_glial_options = [
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'},
    {key:'glial', value:'glial', text:'Glial'},
    {key:'non_glial', value:'non_glial', text:'Non-glial'}
];

const npa_grade_options = [
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'},
    {key:'low_grade', value:'low_grade', text:'Low-grade'},
    {key:'high_grade', value:'high_grade', text:'High-grade'}
];

const npa_lymphoma_options = [
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'},
    {key:'lymphoma', value:'lymphoma', text:'Lymphoma'},
    {key:'something_else', value:'something_else', text:'Something else'}
];

const npa_metastatic_options = [
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'},
    {key:'metastatic', value:'metastatic', text:'Metastatic'},
    {key:'Meningioma', value:'Meningioma', text:'Meningioma'},
    {key:'something_else', value:'something_else', text:'Something else'}
];

const ipa_yesno_options = [
    {key:'yes', value:'yes', text:'Yes'},
    {key:'no', value:'no', text:'No'}
];

const ipa_malignant_options = [
    {key:'benign', value:'benign', text:'Benign'},
    {key:'malignant', value:'malignant', text:'Malignant'},
    {key:'cannot_determine', value:'cannot_determine', text:'Cannot determine'}
];

const breast_nyu_adequacy_options = [
    {key:'adequate_radiologic_lesion', value:'adequate_radiologic_lesion', text:'Adequate (radiologic lesion present)'},
    {key:'inadequate_lesion_absent', value:'inadequate_lesion_absent', text:'Inadequate (radiologic lesion absent)'}
];

const breast_nyu_lesion_options = [
    {key:'lesion_benign', value:'lesion_benign', text: 'Benign'},
    {key:'lesion_malignant', value:'lesion_malignant', text: 'Malignant'},
    {key:'lesion_cannot_determine', value:'lesion_cannot_determine', text:'Cannot be determined'}
];

const breast_nyu_malignant_options = [
    {key:'malignant_cis', value:'malignant_cis', text: 'Carcinoma in situ'},
    {key:'malignant_invasive_carcinoma', value:'malignant_invasive_carcinoma', text: 'Invasive carcinoma'},
    {key:'malignant_cannot_determine', value:'malignant_cannot_determine', text:'Cannot be determined'}
];

const hoag_gi_feature_multiselect = [
    {label:'Benign islet', value:'benign_islet'},
    {label:'Benign ducts', value:'benign_ducts'},
    {label:'Benign acinar cells', value:'benign_acinar_cells'},
    {label:'Benign inflammatory cells', value:'benign_inflammatory_cells'},
    {label:'Atypical cells - glandular', value:'atypical_glandular'},
    {label:'Atypical cells - neuroendocrine', value:'atypical_neuroendocrine'},
    {label:'Atypical cells - uncertain type', value:'atypical_uncertain'}
];

const hoag_gi_likely_diagnosis = [
    {key:'non_diagnostic', value:'non_diagnostic', text:"Non diagnostic (incl. benign element that don't explain clinical mass, fibrous tissue, low image quality"},
    {key:'benign_lesion', value:'benign_lesion', text:"Benign lesion (incl. benign neoplasm, inflammation, cysts)"},
    {key:'malignant', value:'malignant', text:"Malignant"},
    {key:'neo_favor_malignant', value:'neo_favor_malignant', text:"Neoplasm, favor malignant"},
    {key:'acus', value:'acus', text:"Abnormality of uncertain significance"}
];

const hoag_gi_malignant = [
    {key:'adenocarcinoma', value:'adenocarcinoma', text: "Adenocarcinoma"},
    {key:'neuroendocrine_tumor', value:'neuroendocrine_tumor', text:"Neuroendocrine Tumor"},
    {key:'other', value:'other', text:"Other (includes metastatic lesions to the pancreas and lymphoma)"}
];

export default class Viewer extends Component {
    constructor(props) {
        super(props);

        scalebar_enable(OpenSeadragon);
        guide_enable(OpenSeadragon);

        var barcode = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1).substring(5).replace(".dzi","");
        barcode = barcode.substring(0, barcode.lastIndexOf("_"));

        var date = new Date();
        const date_string = date.toISOString();

        this.state = {
            showModal: false,
            feedback: "Not Set",
            globalViewer: undefined,
            patientHistory: "",
            is_core:0,
            caseNumber:"",
            barcode:barcode,
            coreLink:"",
            cellularity_value:"Not Set",
            diagnosis_value:"Not Set",
            nio_image_quality_value:"Not Set",
            nio_helpful_value:"Not Set",
            nio_adequate_value:"Not Set",
            new_margin_value:"Not Set",
            multi_select_value: [],
            other_value:"",
            animation_record: [],
            open_time: date_string,
            submit_time: date_string,
            institution: ""
        };

        // This binding is necessary to make `this` work in the callback
        this.hoaghandleOpenModal = this.hoaghandleOpenModal.bind(this);
        this.hoaghandleCloseModal = this.hoaghandleCloseModal.bind(this);

        this.hoathandleOpenModal = this.hoathandleOpenModal.bind(this);
        this.hoathandleCloseModal = this.hoathandleCloseModal.bind(this);

        this.submitPressed = this.submitPressed.bind(this);
        this.nyu_mustafa_submit_pressed = this.nyu_mustafa_submit_pressed.bind(this);
        this.uncp_submit_pressed = this.uncp_submit_pressed.bind(this);
        this.hoag_gi_submit_pressed = this.hoag_gi_submit_pressed.bind(this);

        this.path_submit_pressed = this.path_submit_pressed.bind(this);
        this.nyuu_submit_pressed = this.nyuu_submit_pressed.bind(this);
        this.umhs_submit_pressed = this.umhs_submit_pressed.bind(this);
        this.stan_submit_pressed = this.stan_submit_pressed.bind(this);
        this.hoag_submit_pressed = this.hoag_submit_pressed.bind(this);
        this.hoat_submit_pressed = this.hoat_submit_pressed.bind(this);
        this.afaf_submit_pressed = this.afaf_submit_pressed.bind(this);
        this.cellularity_change = this.cellularity_change.bind(this);
        this.diagnosis_change = this.diagnosis_change.bind(this);
        this.other_change = this.other_change.bind(this);

        this.nio_image_quality_change = this.nio_image_quality_change.bind(this);
        this.nio_helpful_change = this.nio_helpful_change.bind(this);
        this.nio_adequate_change = this.nio_adequate_change.bind(this);
        this.new_margin_change = this.new_margin_change.bind(this);
        this.hoag_multiselect_change = this.hoag_multiselect_change.bind(this);

        this.tick = this.tick.bind(this);
    }

    hoaghandleOpenModal () {
        if (this.state.diagnosis_value === "Not Set") {
            alert("Diagnosis value is empty");
            return;
        }

        if (this.state.nio_helpful_value === "Not Set") {
            alert("Confidence value is empty");
            return;
        }

        if (this.state.nio_adequate_value === "Not Set") {
            alert("Frozen Section value is empty");
            return;
        }

        this.setState({ showModal: true });
    }
  
    hoaghandleCloseModal () {
        this.setState({ showModal: false });
    }

    hoathandleOpenModal () {
        if (this.state.diagnosis_value === "Not Set") {
            alert("Diagnosis value is empty");
            return;
        }

        this.setState({ showModal: true });
    }
  
    hoathandleCloseModal () {
        this.setState({ showModal: false });
    }

    afaf_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "cellularity_value": "none",
                    "diagnosis_value": "none",
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string,
                    "other_value": this.state.other_value
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    umhs_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "cellularity_value": "none",
                    "diagnosis_value": "none",
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string,
                    "other_value": this.state.other_value
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    path_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "cellularity_value": "none",
                    "diagnosis_value": "none",
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string,
                    "other_value": this.state.other_value
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    nyu_mustafa_submit_pressed() {
        if (this.state.diagnosis_value === "Not Set") {
            alert("Lesion or Normal is empty");
            return;
        }

        if ((this.state.diagnosis_value === "lesion_neoplasm") && (this.state.nio_helpful_value == "Not Set")) {
            alert("Intra/extra-axial is empty with neoplasm selected.");
            return;
        }

        if ((this.state.diagnosis_value === "lesion_neoplasm") && ((this.state.other_value == "Not Set") || (this.state.other_value == ""))) {
            alert("Lesion type is empty.");
            return;
        }

        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_stanford_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.nio_image_quality_value,
                    "nio_helpful_value": this.state.nio_helpful_value,
                    "nio_adequate_value": this.state.nio_adequate_value,
                    "nio_new_margin_value": this.state.new_margin_value,
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    uncp_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_uncp_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.nio_image_quality_value,
                    "nio_helpful_value": this.state.nio_helpful_value,
                    "nio_adequate_value": this.state.nio_adequate_value,
                    "nio_new_margin_value": this.state.new_margin_value,
                    "cellularity_value": this.state.cellularity_value,
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    hoag_gi_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_uncp_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.multi_select_value.map(function(a) {return a['value']}).join(','),
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }


    nyuu_submit_pressed() {
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "cellularity_value": "none",
                    "diagnosis_value": "none",
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string,
                    "other_value": this.state.other_value
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    stan_submit_pressed() {
        if (this.state.nio_image_quality_value === "Not Set") {
            alert("NIO image quality value is empty");
            return;
        }

        if (this.state.nio_helpful_value === "Not Set") {
            alert("NIO helpful value is empty");
            return;
        }

        if (this.state.nio_adequate_value === "Not Set") {
            alert("NIO adequate value is empty");
            return;
        }

        if ((this.state.diagnosis_value === "Other (Free Text)") && (this.state.other_value.length < 2)) {
            alert("Other text field is blank while selected diagnosis is 'other'");
            return;
        }

        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_stanford_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.nio_image_quality_value,
                    "nio_helpful_value": this.state.nio_helpful_value,
                    "nio_adequate_value": this.state.nio_adequate_value,
                    "nio_new_margin_value": this.state.new_margin_value,
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    hoag_submit_pressed() {
        if (this.state.diagnosis_value === "Not Set") {
            alert("Diagnosis value is empty");
            return;
        }

        if (this.state.nio_helpful_value === "Not Set") {
            alert("Confidence value is empty");
            return;
        }

        if (this.state.nio_adequate_value === "Not Set") {
            alert("Frozen Section value is empty");
            return;
        }

        var date = new Date();
        const date_string = date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_hoag_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.nio_image_quality_value,
                    "nio_helpful_value": this.state.nio_helpful_value,
                    "nio_adequate_value": this.state.nio_adequate_value,
                    "nio_new_margin_value": this.state.new_margin_value,
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    hoat_submit_pressed() {
        if (this.state.diagnosis_value === "Not Set") {
            alert("Diagnosis value is empty");
            return;
        }

        var date = new Date();
        const date_string = date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_hoag_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "diagnosis_value": this.state.diagnosis_value,
                    "nio_image_quality_value": this.state.nio_image_quality_value,
                    "other_value": this.state.other_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    submitPressed() {
        if (this.state.cellularity_value === "Not Set") {
            alert("Cellularity Score is empty");
            return;
        }

        if ((this.state.is_core === 1) && (this.state.diagnosis_value === "Not Set")) {
            alert("Diagnosis is not entered -- required for core tissue");
            return;
        }
        
        if ((this.state.diagnosis_value === "Other (Free Text)") && (this.state.other_value.length < 2)) {
            alert("Other text field is blank while selected diagnosis is 'other'");
            return;
        }
        var date = new Date();
        const date_string = date.toISOString();
        this.setState({submit_time: date_string});

        var identifier_string = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        fetch(BASE_API_URL + "/submit_read",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "identifier_string": identifier_string,
                    "cellularity_value": this.state.cellularity_value,
                    "diagnosis_value": this.state.diagnosis_value,
                    "animation_record": this.state.animation_record,
                    "open_time": this.state.open_time,
                    "submit_time": date_string,
                    "other_value": this.state.other_value
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                console.log("ERROR:");
                console.log(data);
                alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + JSON.stringify(data));
            } else {
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            }
        }).catch(function(err){
            console.log("ERROR:");
            console.log(err);
            alert("Error saving information please email balaji@invenio-imaging.com with screenshot: " + err.toString());
        })
    }

    hoag_multiselect_change(data) {
        // Alphabetize selections
        data.sort(function(a,b,) {
            if (a.label < b.label) return -1;
            else return 1;
        });
        this.setState({multi_select_value:data})
    }

    nio_image_quality_change(event, data) {
        this.setState({
            nio_image_quality_value: data.value
        })
    }

    nio_helpful_change(event, data) {
        this.setState({
            nio_helpful_value: data.value
        })
    }

    nio_adequate_change(event, data) {
        this.setState({
            nio_adequate_value: data.value
        })
    }

    new_margin_change(event, data) {
        this.setState({
            new_margin_value: data.value
        })
    }

    cellularity_change(event, data) {
        this.setState({
            cellularity_value: data.value
        })
    }

    diagnosis_change(event, data) {
        this.setState({
            diagnosis_value: data.value
        })
    }

    other_change(event, data) {
        this.setState({
            other_value: data.value
        })
    }
    
    zoom_handler(top_left_x_pixel, top_left_y_pixel, bot_right_x_pixel, bot_right_y_pixel) {
        var bounds = this.state.globalViewer.viewport.getBounds(true);
        var currentRect = this.state.globalViewer.viewport.viewportToImageRectangle(bounds);
        var rect = this.state.globalViewer.viewport.imageToViewportRectangle(top_left_x_pixel, top_left_y_pixel, bot_right_x_pixel - top_left_x_pixel, bot_right_y_pixel - top_left_y_pixel);
        this.state.globalViewer.viewport.fitBoundsWithConstraints(rect);
    }

    zoom_button(top_left_x_pixel, top_left_y_pixel, bot_right_x_pixel, bot_right_y_pixel) {
        return (
            <div>
            <br></br>
            <Button primary onClick={this.zoom_handler.bind(this,529,560,943,871)}>Vasculature</Button>
            </div>
        );
    }

    submit_component(institution) {
        if ((institution === "MSHS") || (institution === "PATH")) {

            var thinviewer_link = "";
            if (this.state.animation_record.length > 0) {
                var thin_x1 = this.state.animation_record[this.state.animation_record.length-1].toString().split(",")[0];
                var thin_y1 = this.state.animation_record[this.state.animation_record.length-1].toString().split(",")[1];
                var thin_x2 = this.state.animation_record[this.state.animation_record.length-1].toString().split(",")[2];
                var thin_y2 = this.state.animation_record[this.state.animation_record.length-1].toString().split(",")[3];
                thinviewer_link = window.location.href.replace("Viewer","Thinviewer") + "?x1=" + thin_x1.toString() + "&y1=" + thin_y1.toString() + "&x2=" + thin_x2.toString() + "&y2=" + thin_y2.toString();
            }

            return (
                <Grid.Column>
                    <Input fluid placeholder='Feature...' onChange={this.other_change}/>

                    <h3>Location:</h3>
                    
                    {this.state.animation_record.length > 0 ? (
                        thinviewer_link
                      ) : (
                        <div>Move viewer to get coordinates</div>
                    )}


                    <br></br>
                    <Button primary onClick={this.path_submit_pressed}>Submit</Button>
                </Grid.Column>
            );
        } else if (institution === "NYUU") {
            return (
                <Grid.Column>
                    <Input fluid placeholder='Note...' onChange={this.other_change}/>

                    <br></br>
                    <Button primary onClick={this.nyuu_submit_pressed}>Submit</Button>
                </Grid.Column>
            );
        } else if (institution === "UCSF") {
            return (
                <Grid.Column>
                    {(this.state.is_core === 1) ?
                        <div>
                            <h3>Diagnosis:</h3>
                            <Dropdown
                                placeholder='Select Diagnosis'
                                fluid
                                search
                                selection
                                onChange={this.diagnosis_change}
                                options={diagnosis_options}
                            />
                            <br></br>
                            <Input fluid placeholder='Other...' onChange={this.other_change}/>
                        </div>
                        : <></>
                    }

                    <h3>Cellular Density:</h3>
                    
                    <h5>
                        0 = No definitive tumor cells present<br></br>
                        1 = Low abundance of infiltrating tumor cells<br></br>
                        2 = Moderate abundance of infiltrating tumor cells<br></br>
                        3 = Highly cellular with abundant tumor cells
                    </h5>
        
                    <Dropdown
                        placeholder='Cellularity'
                        fluid
                        search
                        selection
                        onChange={this.cellularity_change}
                        options={cellularity_options}
                    />

                    <br></br>
                    <Button primary onClick={this.submitPressed}>Submit</Button>
                </Grid.Column>
            );
        } else if (institution === "UMHS") {
            return (
                <Grid.Column>
                    <div>
                        <Input fluid placeholder='Interpretation...' onChange={this.other_change}/>
                    </div>

                    <br></br>
                    <Button primary onClick={this.umhs_submit_pressed}>Submit</Button>
                </Grid.Column>
            );
        } else if ((institution === "STAN") || (institution === "MTSH")) {
            return (
                <Grid.Column>
                    <div>
                        <h3>NIO Image Quality:</h3>
                        <Dropdown
                            placeholder='Select Rating'
                            fluid
                            search
                            selection
                            onChange={this.nio_image_quality_change}
                            options={nio_image_quality_options}
                        />
                        <h3>Was NIO image helpful or additive to frozen/smear preparation:</h3>
                        <Dropdown
                            placeholder='Yes/No'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={yes_no_options}
                        />
                        <h3>Was NIO image alone adequate for intraoperative consultation:</h3>
                        <Dropdown
                            placeholder='Yes/No'
                            fluid
                            search
                            selection
                            onChange={this.nio_adequate_change}
                            options={yes_no_options}
                        />
                        <div class="ui divider"></div>
                        <h3>Diagnosis (optional):</h3>
                        <Dropdown
                            placeholder='Select Diagnosis'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={diagnosis_options}
                        />
                        <h3>Margin Score (optional):</h3>
                        <Dropdown
                            placeholder='Select Margin Score'
                            fluid
                            search
                            selection
                            onChange={this.new_margin_change}
                            options={new_cellularity_options}
                        />

                        <div class="ui divider"></div>
                        <br></br>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.other_change}/>
                        </div>

                        <br></br>
                        <Button primary onClick={this.stan_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "HOAG") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Diagnosis (optional):</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.other_change}/>
                        </div>

                        <h3>Pathology feedback/other comments (optional):</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.nio_image_quality_change}/>
                        </div>


                        <h3>Diagnostic Category:</h3>
                        <Dropdown
                            placeholder='Diagnostic Category'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={hoag_interpretation_categories}
                        />


                        <h3>What is your confidence in assigning this diagnostic category (1 = low confidence, 10 = very confidence):</h3>
                        <Dropdown
                            placeholder='1-10'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={confidence_options}
                        />


                        <h3>Do you require a frozen section to confidently assign this case to a diagnostic category?</h3>
                        <Dropdown
                            placeholder='Yes/No'
                            fluid
                            search
                            selection
                            onChange={this.nio_adequate_change}
                            options={hoag_yes_no_options}
                        />
                        <Button primary onClick={this.hoaghandleOpenModal}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if ((institution == "NYUA") || (institution == 'NYUB') || (institution == 'NYU1') || (institution == 'NYU2')) {
            return (
                <Grid.Column>
                    <div>
                        <h3>Is a lesional (abnormal) tissue present, or is it normal brain? “Normal brain” also includes “mildly hyper-cellular.<br></br>If abnormal, is it a neoplasm or non-neoplastic disease? Non-neoplastic disease includes abscess, inflammatory, and demyelinating.</h3>
                        <Dropdown
                            placeholder='Diagnostic Category'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={nyu_lesion}
                        />


                        <h3>If the lesion is neoplastic, is it intra-axial or extra-axial:</h3>
                        <Dropdown
                            placeholder='intra-axial/extra-axial'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={nyu_intra_extra_axial}
                        />


                        <h3>If intra-axial, is it glial, non-glial, metastatic, high grade or low grade?</h3>
                        <Dropdown
                            placeholder='intra-axial type'
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={nyu_intra_axial_types}
                        />


                        <h3>If extra-axial, is it metastatic, a meningioma, or something else (e.g., chordoma, HPC)?</h3>
                        <Dropdown
                            placeholder='extra-axial type'
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={nyu_extra_axial_types}
                        />

                        <Button primary onClick={this.nyu_mustafa_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "UNCP") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Diagnosis:</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.other_change}/>
                        </div>

                        <h3>Pathology feedback/other comments (optional):</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.nio_image_quality_change}/>
                        </div>


                        <h3>Diagnostic Category:</h3>
                        <Dropdown
                            placeholder='Diagnostic Category...'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={uncp_diagnostic_types}
                        />


                        <h3>Morphological Features Present:</h3>
                        <Dropdown
                            placeholder='Morphological Features...'
                            fluid
                            search
                            selection
                            onChange={this.cellularity_change}
                            options={uncp_morphological_features_types}
                        />


                        <h3>What is your confidence in assigning this diagnostic categoy (1 = low confidence, 10 = very confidence)</h3>
                        <Dropdown
                            placeholder='Confidence...'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={confidence_options}
                        />


                        <h3>Comparison to H&E:</h3>
                        <Dropdown
                            placeholder='Comparison to HE'
                            fluid
                            search
                            selection
                            onChange={this.nio_adequate_change}
                            options={uncp_he_concordant_types}
                        />

                        <Button primary onClick={this.uncp_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "NP-A") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Is lesional tissue, non-lesional tissue, or reactive tissue present?</h3>
                        <Dropdown
                            placeholder="Lesional..."
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={npa_lesional_options}
                        />

                        <h3>If lesional tissue is present, is it intra-axial or extra-axial?</h3>
                        <Dropdown
                            placeholder="Axial..."
                            fluid
                            search
                            selection
                            onChange={this.nio_image_quality_change}
                            options={npa_axial_options}
                        />


                        <h3>If intra-axial, is it glial or non-glial?</h3>
                        <Dropdown
                            placeholder='Glial...'
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={npa_glial_options}
                        />


                        <h3>If the lesional tissue is intra-axial and glial, is it low-grade or high-grade?</h3>
                        <Dropdown
                            placeholder='Grade...'
                            fluid
                            search
                            selection
                            onChange={this.cellularity_change}
                            options={npa_grade_options}
                        />


                        <h3>If the lesional tissue is intra-axial and non-glial, is it a lymphoma or something else?</h3>
                        <Dropdown
                            placeholder='Lymphoma...'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={npa_lymphoma_options}
                        />


                        <h3>If extra-axial, is it metastatic, a meningioma, or something else (e.g. chordoma, HPC, or soft-tissue neoplasm)?</h3>
                        <Dropdown
                            placeholder='Something else...'
                            fluid
                            search
                            selection
                            onChange={this.nio_adequate_change}
                            options={npa_metastatic_options}
                        />

                        <Button primary onClick={this.uncp_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "NP-B") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Is lesional tissue, non-lesional tissue, or reactive tissue present?</h3>
                        <Dropdown
                            placeholder="Lesional..."
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={npa_lesional_options}
                        />

                        <h3>If lesional tissue is present, is it glial or non-glial?</h3>
                        <Dropdown
                            placeholder='Glial...'
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={npa_glial_options}
                        />

                        <h3>If glial tissue is present, please classify it as one of the following:</h3>
                        <Dropdown
                            placeholder='Grade...'
                            fluid
                            search
                            selection
                            onChange={this.cellularity_change}
                            options={npb_glial_diagnosis_options}
                        />


                        <h3>If non-glial tissue is present, please classify it as one of the following:</h3>
                        <Dropdown
                            placeholder='Lymphoma...'
                            fluid
                            search
                            selection
                            onChange={this.nio_helpful_change}
                            options={npb_non_glial_diagnosis_options}
                        />

                        <Button primary onClick={this.uncp_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if ((institution == "BP-A") || (institution == "BP-B")) {
            return (
                <Grid.Column>
                    <div>
                        <h3>Is the tissue adequate for diagnosis and downstream analysis??</h3>
                        <Dropdown
                            placeholder="Adequate..."
                            fluid
                            search
                            selection
                            onChange={this.nio_adequate_change}
                            options={breast_nyu_adequacy_options}
                        />

                        <h3>Is the lesion benign or malignant??</h3>
                        <Dropdown
                            placeholder='Lesion...'
                            fluid
                            search
                            selection
                            onChange={this.cellularity_change}
                            options={breast_nyu_lesion_options}
                        />

                        <h3>If this tissue is malignant, is it in-situ or invasion?</h3>
                        <Dropdown
                            placeholder='Malignant...'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={breast_nyu_malignant_options}
                        />


                        <h3>Comment (e.g. specific diagnosis, presence of microcalcifications, etc.):</h3>
                        <div>
                            <Input fluid placeholder='Comment...' onChange={this.other_change}/>
                        </div>
                        <br></br>
                        <Button primary onClick={this.uncp_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "IP-A") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Is the tissue adequate or inadequate for diagnosis and downstream analysis?</h3>
                        <Dropdown
                            placeholder="Adequate..."
                            fluid
                            search
                            selection
                            onChange={this.nio_image_quality_change}
                            options={ipa_yesno_options}
                        />

                        <h3>Is the biopsied tissue, neoplastic or non-neoplastic?</h3>
                        <Dropdown
                            placeholder="Neoplastic..."
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={ipa_yesno_options}
                        />


                        <h3>If the tissue is neoplastic, is it benign or malignant? (If inadequate or non-neoplastic, then select 'cannot determine')</h3>
                        <Dropdown
                            placeholder='Malignant...'
                            fluid
                            search
                            selection
                            onChange={this.cellularity_change}
                            options={ipa_malignant_options}
                        />


                        <h3>What is the diagnosis? (If inadequate or non-neoplastic, then enter 'cannot determine')</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.diagnosis_change}/>
                        </div>

                        <Button primary onClick={this.uncp_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "HOGI") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Which of the following features are present in the image?</h3>
                        <MultiSelect
                            options={hoag_gi_feature_multiselect}
                            onChange={this.hoag_multiselect_change}
                            hasSelectAll={false}
                            value={this.state.multi_select_value}
                        />

                        <h3>What is your likely diagnosis?</h3>
                        <Dropdown
                            placeholder="Diagnosis..."
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={hoag_gi_likely_diagnosis}
                        />


                        <h3>If malignant, what subtype?</h3>
                        <Dropdown
                            placeholder='Subtype...'
                            fluid
                            search
                            selection
                            onChange={this.other_change}
                            options={hoag_gi_malignant}
                        />


                        <Button primary onClick={this.hoag_gi_submit_pressed}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "HOAT") {
            return (
                <Grid.Column>
                    <div>
                        <h3>Diagnosis (optional):</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.other_change}/>
                        </div>

                        <h3>Pathology feedback/other comments (optional):</h3>
                        <div>
                            <Input fluid placeholder='Enter Free Text' onChange={this.nio_image_quality_change}/>
                        </div>


                        <h3>Diagnostic Category:</h3>
                        <Dropdown
                            placeholder='Diagnostic Category'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={hoag_final_interpretation_categories}
                        />

                        <Button primary onClick={this.hoathandleOpenModal}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if ((institution == "NIOA") || (institution == "ROCA")) {
            return (
                <Grid.Column>
                    <div>
                        <h3>Diagnostic Category:</h3>
                        <Dropdown
                            placeholder='Diagnostic Category'
                            fluid
                            search
                            selection
                            onChange={this.diagnosis_change}
                            options={nioa_interpretation_categores}
                        />

                        <Button primary onClick={this.hoathandleOpenModal}>Submit</Button>
                    </div>
                </Grid.Column>
            ); 
        } else if (institution == "AFAF") {
            var point = 'No AF Line';
            if ((this.state.institution == "AFAF") && (this.state.globalViewer != undefined)) {
                if (this.state.globalViewer.guidesInstance.guides[0] != undefined) {
                    point = 1000.0 * this.state.globalViewer.guidesInstance.guides[0].point.y;

                    if (this.state.other_value != point) {
                        this.setState({'other_value': point});
                    }
                }
            }

            return (
                <Grid.Column>
                    <p>
                    1. Assess image. If not suitable AF line, just press submit.<br></br>
                    2. If suitable AF line, press the horizontal guide button and drag line to location.<br></br>
                    3. Press submit. If you made a mistake, just refresh the page to start over.
                    </p>
                    <div>
                        <Input fluid placeholder='AF Line value...' value={point} />
                    </div>

                    <br></br>
                    <Button primary onClick={this.afaf_submit_pressed}>Submit</Button>
                </Grid.Column>
            );
        } else {
            return (
                <div>
                    No Submission Panel for your institution
                </div>
            );
        }
    }

    tick() {
        if (this.state.globalViewer.world.getItemAt(0) === undefined) {
            // no image is loaded -- this is a bad viewer url
            return;
        }

        var zoom = this.state.globalViewer.viewport.getZoom(true);
        var imageZoom = this.state.globalViewer.viewport.viewportToImageZoom(zoom);
        var image_width = parseInt(this.state.globalViewer.world.getItemAt(0).getContentSize().x);
        var image_height = parseInt(this.state.globalViewer.world.getItemAt(0).getContentSize().y);
        var x_pixels = parseFloat(this.state.globalViewer.viewport.getBounds(true).x) * parseFloat(image_width);
        var y_pixels = parseFloat(this.state.globalViewer.viewport.getBounds(true).y) * parseFloat(image_height);
        var width_pixels = parseFloat(this.state.globalViewer.viewport.getBounds(true).width) * parseFloat(image_width);
        var height_pixels = parseFloat(this.state.globalViewer.viewport.getBounds(true).height) * parseFloat(image_height);

        var top_left_x_pixel = parseInt(x_pixels);
        var top_left_y_pixel = parseInt(y_pixels);
        var bot_right_x_pixel = parseInt(x_pixels + width_pixels);
        var bot_right_y_pixel = parseInt(y_pixels + height_pixels);


        var previous_state = this.state.animation_record;
        var new_state = previous_state;
        if (previous_state.length === 0) {
            new_state = [[top_left_x_pixel, top_left_y_pixel, bot_right_x_pixel, bot_right_y_pixel, 1]];
        } else if (previous_state.length > 600) {
            // more than 10 minutes of clicking and moving -- just ignore any more
            return;
        } else {
            var last_state_available = previous_state[previous_state.length-1];

            if ((top_left_x_pixel === last_state_available[0]) &&
                (top_left_y_pixel === last_state_available[1]) &&
                (bot_right_x_pixel === last_state_available[2]) &&
                (bot_right_y_pixel === last_state_available[3])) {
                
                new_state[new_state.length - 1][4] += 1;
            } else {
                new_state.push([top_left_x_pixel, top_left_y_pixel, bot_right_x_pixel, bot_right_y_pixel, 1]);
            }
        }
        this.setState({
            animation_record: new_state
        });
    }

    componentDidMount() {
        fetch(BASE_API_URL + "/get_information_by_barcode_string",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('authorization')
                    // "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({"identifier_string": this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1)})
            }).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] !== "OK") {
                localStorage.removeItem('authorization');
                window.location.href = BASE_CLIENT_URL;
            } else {
                this.setState({
                    patientHistory: data['data'][0]['patient_history'],
                    is_core: data['data'][0]['is_core'],
                    caseNumber: data['data'][0]['randomized_case_number'],
                    institution: data['institution'],
                    feedback : data['data'][0]['feedback']
                });
            }

            if (this.state.is_core !== 1) {
                fetch(BASE_API_URL + "/get_core_information_by_barcode_string",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "authorization": localStorage.getItem('authorization')
                        },
                        body: JSON.stringify({"identifier_string": this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1)})
                    }).then(function(response) {
                        return response.json();
                    }).then(function(data) {
                        if (data['status'] !== "OK") {
                            localStorage.removeItem('authorization');
                            window.location.href = BASE_CLIENT_URL;
                        } else {
                            this.setState({
                                coreLink: data['data'][0]['NIO_barcode'] + "_" + data['data'][0]['scan_number'] + ".dzi"
                            });
                        }
                    }.bind(this)).catch(function(sub_err) {
                        console.log(sub_err);
                        this.setState({coreLink: "ERROR"});
                    }.bind(this));
            }


            var pixelsPerMeter = 1;
            if ((this.state.is_core === 0) || (this.state.is_core === 1) || (this.state.is_core === 2)) {
                pixelsPerMeter = 2000000;
            } else if (this.state.is_core === 3) {
                pixelsPerMeter = 4000000;
            } else {
                pixelsPerMeter = 4000000;
            }

            var viewer = undefined;

            if (this.state.institution == "STAN") {
                // Add GB image
                viewer = OpenSeadragon({
                    id: "openseadragon1",
                    prefixUrl: "/images/",
                    showNavigator:  true,
                    maxZoomLevel: 25,
                    loadTilesWithAjax:true,
                    ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                    tileSources: [
                                    BASE_API_URL + '/slides/' + this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1),
                                    BASE_API_URL + '/slides/' + this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1).replace(".dzi", "GB.dzi")
                                ],
                    sequenceMode: true,
                    showReferenceStrip: true,
                    gestureSettingsMouse: {clickToZoom: false},
                    gestureSettingsTouch: {clickToZoom: false}
                });
            } else if (this.state.institution == "HOAG") {
                // Only show 2 images (preselected by Steve)

                var img_1 = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
                var img_2 = "";
                const case_number = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf("_Case")+5, this.props.location.pathname.lastIndexOf("-"));
                if (case_number == "1") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "2") {
                    img_2 = img_1;
                } else if (case_number == "3") {
                    img_2 = img_1.replace("2_1.dzi", "5_1.dzi");
                } else if (case_number == "4") {
                    img_2 = img_1.replace("1_1.dzi", "3_1.dzi");
                } else if (case_number == "5") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "6") {
                    img_2 = img_1.replace("1_1.dzi", "4_1.dzi");
                } else if (case_number == "7") {
                    img_2 = img_1.replace("1_1.dzi", "3_1.dzi");
                } else if (case_number == "8") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "9") {
                    img_2 = img_1;
                } else if (case_number == "10") {
                    img_2 = img_1.replace("2_1.dzi", "4_1.dzi");
                } else if (case_number == "11") {
                    img_2 = img_1;
                } else if (case_number == "12") {
                    img_2 = img_1.replace("1_1.dzi", "3_1.dzi");
                } else if (case_number == "13") {
                    img_2 = img_1.replace("2_1.dzi", "4_1.dzi");
                } else if (case_number == "14") {
                    img_2 = img_1.replace("2_1.dzi", "3_1.dzi");
                } else if (case_number == "15") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "16") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "17") {
                    img_2 = img_1.replace("2_1.dzi", "3_1.dzi");
                } else if (case_number == "18") {
                    img_2 = img_1;
                } else if (case_number == "19") {
                    img_2 = img_1.replace("2_1.dzi", "3_1.dzi");
                } else if (case_number == "20") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else {
                    img_2 = img_1;
                }


                viewer = OpenSeadragon({
                    id: "openseadragon1",
                    prefixUrl: "/images/",
                    showNavigator:  true,
                    maxZoomLevel: 25,
                    loadTilesWithAjax:true,
                    ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                    tileSources: [
                                    BASE_API_URL + '/slides/' + img_1,
                                    BASE_API_URL + '/slides/' + img_2
                                ],
                    sequenceMode: true,
                    showReferenceStrip: true,
                    gestureSettingsMouse: {clickToZoom: false},
                    gestureSettingsTouch: {clickToZoom: false}
                });
            } else if (this.state.institution == "HOAT") {
                // Only show 2 images (preselected by Steve)

                var img_1 = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
                var img_2 = "";
                const case_number = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf("_FinalCase")+10, this.props.location.pathname.lastIndexOf("-"));
                console.log(case_number);
                if (case_number == "1") {
                    img_2 = img_1;
                } else if (case_number == "2") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "3") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "4") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "5") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "6") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "7") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "8") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "9") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "10") {
                    img_2 = img_1;
                } else if (case_number == "11") {
                    img_2 = img_1;
                } else if (case_number == "12") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "13") {
                    img_2 = img_1;
                } else if (case_number == "14") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "15") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "16") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "17") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "18") {
                    img_2 = img_1.replace("1_1.dzi", "2_1.dzi");
                } else if (case_number == "19") {
                    img_2 = img_1;
                } else if (case_number == "20") {
                    img_2 = img_1;
                } else {
                    img_2 = img_1;
                }


                if (img_1 == img_2) {
                    viewer = OpenSeadragon({
                        id: "openseadragon1",
                        prefixUrl: "/images/",
                        showNavigator:  true,
                        maxZoomLevel: 25,
                        loadTilesWithAjax:true,
                        ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                        tileSources: [
                                        BASE_API_URL + '/slides/' + img_1
                                    ],
                        sequenceMode: true,
                        showReferenceStrip: true,
                        gestureSettingsMouse: {clickToZoom: false},
                        gestureSettingsTouch: {clickToZoom: false}
                    });
                } else {
                    viewer = OpenSeadragon({
                        id: "openseadragon1",
                        prefixUrl: "/images/",
                        showNavigator:  true,
                        maxZoomLevel: 25,
                        loadTilesWithAjax:true,
                        ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                        tileSources: [
                                        BASE_API_URL + '/slides/' + img_1,
                                        BASE_API_URL + '/slides/' + img_2
                                    ],
                        sequenceMode: true,
                        showReferenceStrip: true,
                        gestureSettingsMouse: {clickToZoom: false},
                        gestureSettingsTouch: {clickToZoom: false}
                    });
                }
            } else if (this.state.institution == "AFAF") {
                viewer = OpenSeadragon({
                    id: "openseadragon1",
                    prefixUrl: "/images/",
                    showNavigator:  true,
                    maxZoomLevel: 25,
                    loadTilesWithAjax:true,
                    ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                    tileSources: [
                                    BASE_API_URL + '/slides/' + this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1)
                                ],
                    sequenceMode: true,
                    showReferenceStrip: true,
                    gestureSettingsMouse: {clickToZoom: false},
                    gestureSettingsTouch: {clickToZoom: false},
                    imageSmoothingEnabled: false
                });

                viewer.guides({
                  allowRotation: false,        // Make it possible to rotate the guidelines (by double clicking them)
                  horizontalGuideButton: null, // Element for horizontal guideline button
                  verticalGuideButton: null,   // Element for vertical guideline button
                  prefixUrl: null,             // Images folder
                  removeOnClose: false,        // Remove guidelines when viewer closes
                  useSessionStorage: false,    // Save guidelines in sessionStorage
                  navImages: {
                    guideHorizontal: {
                      REST: 'guidehorizontal_rest.png',
                      GROUP: 'guidehorizontal_grouphover.png',
                      HOVER: 'guidehorizontal_hover.png',
                      DOWN: 'guidehorizontal_pressed.png'
                    },
                    guideVertical: {
                      REST: 'guidevertical_rest.png',
                      GROUP: 'guidevertical_grouphover.png',
                      HOVER: 'guidevertical_hover.png',
                      DOWN: 'guidevertical_pressed.png'
                    }
                  }
                });
            } else {
                viewer = OpenSeadragon({
                    id: "openseadragon1",
                    prefixUrl: "/images/",
                    showNavigator:  true,
                    maxZoomLevel: 80,
                    loadTilesWithAjax:true,
                    ajaxHeaders : {"authorization": localStorage.getItem('authorization')},
                    tileSources: [
                                    BASE_API_URL + '/slides/' + this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1)
                                ],
                    sequenceMode: true,
                    showReferenceStrip: true,
                    gestureSettingsMouse: {clickToZoom: false},
                    gestureSettingsTouch: {clickToZoom: false}
                });
            }

            this.setState({globalViewer: viewer});

            this.state.globalViewer.scalebar({
                type: OpenSeadragon.ScalebarType.MICROSCOPY,
                pixelsPerMeter: pixelsPerMeter,
                minWidth: "75px",
                location: OpenSeadragon.ScalebarLocation.TOP_LEFT,
                xOffset: 10,
                yOffset: 50,
                stayInsideImage: true,
                color: "rgb(100, 100, 100)",
                fontColor: "rgb(40, 40, 40)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                fontSize: "small",
                barThickness: 2
            });

            var date = new Date();
            const date_string = date.toISOString();
            this.setState({open_time: date_string});
            this.interval = setInterval(() => this.tick(), 1000);

        }.bind(this)).catch(function(err) {
            console.log(err);
            this.setState({patientHistory: "ERROR"});
        }.bind(this));
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let submit_button;
        if (this.state.institution == 'HOAG') {
          submit_button = <button onClick={this.hoag_submit_pressed}>Continue</button>;
        } else {
          submit_button = <button onClick={this.hoat_submit_pressed}>Continue</button>;
        }

        return (
        <div style={{fontFamily:'Helvetica'}}>
            <Modal 
               isOpen={this.state.showModal}
               style={modal_style}
               contentLabel="Minimal Modal Example"
            >

                <h2>
                    <div style={line_break_style}>{this.state.feedback} </div>
                    <br></br>
                    <br></br>
                    {submit_button}
                </h2>
            </Modal>

            <TopHeader></TopHeader>

            <div style={{backgroundColor:'#eee', marginTop:'2em', paddingBottom:'1em'}}>
                <p></p>
                <Container style={{paddingTop:'20px'}}>
                    <div className="ui huge header" style={{fontSize:'1.5em', paddingLeft:'16px'}}>Case #{this.state.caseNumber} - NIO_barcode: {this.state.barcode}</div>
                    <div style={{fontSize:'1.5em', paddingLeft:'16px'}}><b>Patient History: </b>{this.state.patientHistory}</div>
                </Container>
            </div>

            <Container>
                <center>
                    <div id="openseadragon1" style={seadragon_div_style}></div>
                </center>
            </Container>

            <Divider horizontal>Interpretation</Divider>

            <Container>
                <Segment>
                    <Grid columns={2} relaxed='very'>
                    <Grid.Column>
                        <h5>Case Number:</h5>
                        {this.state.caseNumber}

                        <h5>NIO Barcode:</h5>
                        {this.state.barcode}

                        <h5>Patient Information:</h5>
                        {this.state.patientHistory}

                        {(this.state.is_core === 1) ?
                            <h5>This image is core tissue</h5> :
                            <div></div>
                        }

                        {(this.state.is_core === 0) ?
                            <div><h5>Core Specimen:</h5><Button as={Link} onClick={this.forceUpdate} to={"/Viewer/" + this.state.institution + "_" + this.state.coreLink}>Link to core</Button></div>
                            :
                            <div></div>
                        }


                    </Grid.Column>

                    {this.submit_component(this.state.institution)}

                    </Grid>
                    <Divider vertical>Info</Divider>
                </Segment>
            </Container>
            <br></br>
        </div>
        );
    }
}
