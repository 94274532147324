import React, { Component } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment, Menu, Container} from 'semantic-ui-react' // eslint-disable-line no-unused-vars
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars

import {BASE_CLIENT_URL, BASE_API_URL} from './Config';

export default class TopHeader extends Component {
    constructor(props) {
        super(props);
    
        this.state = {institution: "", user: ""};

        // This binding is necessary to make `this` work in the callback
        this.loginClicked = this.loginClicked.bind(this);
        this.institutionChanged = this.institutionChanged.bind(this);
        this.userChanged = this.userChanged.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("authorization") !== null) {
            window.location.href = BASE_CLIENT_URL + "/Homepage/";
        }
    }

    loginClicked() {
        fetch(BASE_API_URL + "/sign_in_user",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "token_1": "c510d446-9103-4e09-b26b-3d07352bfe97",
                    "institution": this.state.institution,
                    "user": this.state.user
                })
            }
        ).then(function(response) {
            return response.json();
        }).then(function(data) {
            if (data['status'] === "OK") {
                localStorage.setItem("authorization", data['token']);
                alert("The content on this website provided by INVENIO IMAGING, INC. is strictly confidential. It contains information intended only for the persons who have been offered access to the site. Recipient acknowledges and agrees that: (i) the material is not to be distributed, in whole or in part, without the express written consent of the Company; (ii) all of the information herein will be treated as confidential material with no less care than that afforded to its own confidential material.");
                window.location.href = BASE_CLIENT_URL + "/Homepage";
            } else {
                alert("Invalid Credentials -- try again or contact Invenio for help");
                localStorage.removeItem('authorization');
            }
        }).catch(function(err) {
            console.log("ERROR: ", err);
        })
    }

    institutionChanged(event, data) {
        if (data.value.toUpperCase().replace("-","").replace("_","") == "HOAGFINAL") {
            this.setState({institution: 'HOAT'});
        } else if (data.value.toUpperCase().replace(" ", "") == "ROCHEATLAS") {
            this.setState({institution: 'ROCA'});
        } else if (data.value.toUpperCase().replace(" ","").startsWith("NYUU")) {
            this.setState({institution: 'NYUU'});
        } else if (data.value.toUpperCase().replace("-","").replace("_","") == "HOAGGI") {
            this.setState({institution: "HOGI"});
        } else {
            this.setState({institution: data.value.toUpperCase().substring(0,4)});
        }
    }

    userChanged(event, data) {
        this.setState({user:data.value});
    }

    render() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        if (isIE) {
            return (
                <div>
                    Internet Explorer is not supported. Please use Chrome or Firefox.
                </div>
            );
        } else {

            return (

                <div className='login-form'>
                {/*
                Heads up! The styles below are necessary for the correct render of this example.
                You can do same with CSS, the main idea is that all the elements up to the `Grid`
                below must have a height of 100%.
                */}
                <style>{`
                body > div,
                body > div > div,
                body > div > div > div.login-form {
                    height: 100%;
                }
                `}
                </style>
                <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='top'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' style={{color:"#053b63"}} textAlign='center'>
                    <Image src='/images/logo2.jpg' /> Sign in to the image portal
                    </Header>
                    <Form size='large'>
                    <Segment stacked>
                        <Form.Input fluid icon='hospital' iconPosition='left' placeholder='Institution' onChange={this.institutionChanged}/>
                        <Form.Input
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='User'
                        type='password'
                        onChange={this.userChanged}
                        />

                        <Button color='blue' fluid size='large' onClick={this.loginClicked}>
                        Login
                        </Button>
                    </Segment>
                    </Form>
                    <Message>
                    Continue as guest? <a href='#'>Click here</a>
                    </Message>
                </Grid.Column>
                </Grid>
                </div>
            );
        }
    }
}