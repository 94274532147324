import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Homepage from './Homepage';
import Viewer from './Viewer';
import Thinviewer from './Thinviewer';
import Login from './Login';

import 'semantic-ui-css/semantic.min.css'

const RouteSet = () => (
  <Router>
    <div>
      <Switch>
        <Route path="/Homepage" component={Homepage} />
        <Route path="/Viewer" component={Viewer} />
        <Route path="/Thinviewer" component={Thinviewer} />
        <Route component ={Login}/>
      </Switch>
    </div>
  </Router>
);

export default RouteSet;
